import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import useAuth from "./hooks/useAuth";
import { ProtectedRoute } from "./pages/ProtectedRoute/ProtectedRoutePage";
import UserPagesRoutes from "./pages/hrProfileDashboard/userPagesRoute";
import Nopage from "./pages/Nopage/Nopage";

const App = () => {
  const authState = useAuth();

  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<UserPagesRoutes />} />
        <Route path="hr/*" element={<UserPagesRoutes></UserPagesRoutes>} />
        <Route path="*" element={<Nopage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
