import { Input } from "antd";
import { useState } from "react";
import HrEmployeeDatabaseList from "./hrEmployeeDatabaseList";
import "../hrEmployeeManagementComp.css";
import { IAuthType } from "../../../../apiservice/authService.type";
import { useAppSelector } from "../../../../Redux/reduxCustomHook";
import { RootState } from "../../../../Redux/store";

export const HrEmployeeDatabaseListWrapper = () => {
  const [payLoadFilter, setpayLoadFilter] = useState<any>({});
  const [externalFilter, setExternalFilter] = useState<any>({});

  const authData: IAuthType = useAppSelector(
    (state: RootState) => state?.AuthData
  );

  // Use to collect Change
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    // console.log(value)
    setpayLoadFilter((values) => ({ ...values, [name]: value }));
    if (name === "status") {
      setExternalFilter({ ...externalFilter, status: value });
    }
  };

  const handleButtonClick = (value) => {
    setpayLoadFilter((values) => ({ ...values }));
  };

  // Use to Update Filter
  const updateFilter = () => {
    setExternalFilter({ ...payLoadFilter });
  };

  return (
    <>
      <div className="w3-col">
        <div className="w3-container">
          {/* Title */}
          <div className="w3-col w3-margin-top">
            <div
              className="w3-col l6 s6 m6"
              style={{ padding: "5px", paddingTop: "15px" }}
            >
              <h2 className="sitePrimaryColor w3-xlarge">
                {" "}
                Employment database{" "}
              </h2>
            </div>

            <div
              className="w3-col l6 s6 m6 w3-right-align"
              style={{ padding: "5px", paddingTop: "15px" }}
            >
              {/* <button
                onClick={() => {
                  setOpenModal(true);
                }}
                className="w3-btn w3-round-large sitePrimaryButton"
              >
                Add User
              </button> */}
            </div>
          </div>

          {/* Filters */}
          <div className="w3-col w3-margin-top">
            <div className="w3-col l7 s12 m12">
              <div className="w3-col" style={{ padding: "5px" }}>
                <Input
                  name="searchstring"
                  value={payLoadFilter?.searchstring || ""}
                  onChange={handleInputChange}
                  onBlur={updateFilter}
                  className="w3-col w3-round-large inputSelect"
                  style={{ height: "50px" }}
                  placeholder="Search Employee"
                />
              </div>
            </div>

            <div className="w3-col l5 s12 m12">
              <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                <select
                  name="status"
                  value={payLoadFilter?.status || "-1"}
                  onChange={handleInputChange}
                  onBlur={updateFilter}
                  className="w3-col w3-round-large inputSelect w3-border"
                  style={{ height: "50px" }}
                >
                  <option value="">Filter Employee</option>
                  <option value="-1"> All Employee</option>
                </select>
              </div>
              <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                <button className="w3-btn w3-col w3-round-large sitePrimaryBorderButton">
                  Search
                </button>
              </div>
            </div>
          </div>

          <div className="w3-col w3-margin-top">
            <HrEmployeeDatabaseList></HrEmployeeDatabaseList>
          </div>
        </div>
      </div>
    </>
  );
};

export default HrEmployeeDatabaseListWrapper;
