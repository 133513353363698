import "./HrAccounting.css";
import HrAccountingTaxListWrapper from "./hrAccountingList/HrAccountingTaxListWrapper";
import HrAccountingMenu from "./HrAccountingMenu";

export const HrAccountingTaxWrapper = () => {
  return (
    <>
      <div className="w3-col" style={{ minHeight: "400px" }}>
        <div className="w3-col" style={{ marginTop: "20px" }}>
          <div className="w3-container">
            <div className="w3-content">
              <div className="w3-col w3-margin-bottom">
                <h2 className="siteBorderPrimaryColor w3-xlarge">
                  Accountant (Tax Manager)
                </h2>
                <br />
                <p>
                  Borem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                  vulputate libero et velit interdum, ac aliquet odio mattis.
                  <br />
                  Class aptent taciti sociosqu ad litora torquent per conubia
                  nostra, per inceptos himenaeos.
                </p>
              </div>

              <HrAccountingMenu></HrAccountingMenu>
              <HrAccountingTaxListWrapper></HrAccountingTaxListWrapper>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HrAccountingTaxWrapper;
