import HrReportsWrapper from "../../../components/HRUsersComp/hrReports/HrReportsWrapper";
import "../userPage.css";

export const HRAnalyticsPage = () => {
  return (
    <>
      <HrReportsWrapper></HrReportsWrapper>
    </>
  );
};

export default HRAnalyticsPage;
