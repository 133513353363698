import HrAccountingBenefitWrapper from "../../../components/HRUsersComp/hrAccounting/HrAccountingBenfitWrapper";
import "../userPage.css";

export const HRAccountBenefitMangerPage = () => {
  return (
    <>
      <HrAccountingBenefitWrapper></HrAccountingBenefitWrapper>
    </>
  );
};

export default HRAccountBenefitMangerPage;
