import { FieldTimeOutlined } from "@ant-design/icons";
import "./HrTimeAndAttedance.css";

export const HrTimeActivtySummary: React.FC<{}> = () => {
  return (
    <>
      <div className="w3-col w3-margin-bottom">
        {/* Work Time */}
        <div className="w3-col l4 s12 m6" style={{ padding: "10px" }}>
          <div className="w3-col w3-border w3-round-xlarge w3-padding">
            <div className="w3-col">
              <div className="w3-col l6 s6 m6">
                <span className="w3-text-black"> Work Time </span>
              </div>
              <div className="w3-col l6 s6 m6 w3-right-align">
                <span className="w3-large w3-text-black">
                  <FieldTimeOutlined />
                </span>
              </div>
            </div>

            <div className="w3-col">
              <div className="w3-col l6 s6 m6">
                <span className="w3-large w3-text-black"> 62 </span>
              </div>
              <div className="w3-col l6 s6 m6 w3-right-align">
                <span className="w3-text-dark-grey w3-tiny">
                  Average Per Shift
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Computer Activity */}
        <div className="w3-col l4 s12 m6" style={{ padding: "10px" }}>
          <div className="w3-col w3-border w3-round-xlarge w3-padding">
            <div className="w3-col">
              <div className="w3-col l6 s6 m6">
                <span className="w3-text-black"> Computer Activity </span>
              </div>
              <div className="w3-col l6 s6 m6 w3-right-align">
                <span className="w3-large w3-text-black">
                  <FieldTimeOutlined />
                </span>
              </div>
            </div>

            <div className="w3-col">
              <div className="w3-col l6 s6 m6">
                <span className="w3-large w3-text-black"> 62 </span>
              </div>
              <div className="w3-col l6 s6 m6 w3-right-align">
                <span className="w3-text-dark-grey w3-tiny">
                  Average Per Shift
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Productive Time */}
        <div className="w3-col l4 s12 m6" style={{ padding: "10px" }}>
          <div className="w3-col w3-border w3-round-xlarge w3-padding">
            <div className="w3-col">
              <div className="w3-col l6 s6 m6">
                <span className="w3-text-black"> Productive Time </span>
              </div>
              <div className="w3-col l6 s6 m6 w3-right-align">
                <span className="w3-large w3-text-black">
                  <FieldTimeOutlined />
                </span>
              </div>
            </div>

            <div className="w3-col">
              <div className="w3-col l6 s6 m6">
                <span className="w3-large w3-text-black"> 62 </span>
              </div>
              <div className="w3-col l6 s6 m6 w3-right-align">
                <span className="w3-text-dark-grey w3-tiny">
                  Average Per Shift
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HrTimeActivtySummary;
