import { LoadingOutlined } from "@ant-design/icons";
import { Input, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useState } from "react";
import { sampleApiCall } from "../../../../apiservice/authService";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import "../HrTraining.css";
const { Option } = Select;

type IHrTrainingFormSetup = {
  onFormSuccess?: () => void;
  onFormFailure?: (err?: string) => void;
};

export const HrTrainingFormSetup: React.FC<IHrTrainingFormSetup> = ({
  onFormFailure,
  onFormSuccess,
}) => {
  const [loadApi, setLoadApi] = useState(false);
  const [payLoad, setpayLoad] = useState<any>(null);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [loadAuth, setLoadAuth] = useState(false);

  // Use to collect Input change Change
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setpayLoad((values) => ({ ...values, [name]: value }));
  };

  const handleSelectChange = (value, name) => {
    setpayLoad((values) => ({ ...values, [name]: value }));
  };

  // Use to Submit Form
  const handleSubmit = (event: any) => {
    event.preventDefault();
    setLoadApi(true);
    setFormLoading(true);
  };

  // A custom hook to Process Form
  const result = useFormatApiRequest(
    () => sampleApiCall(payLoad),
    loadApi,
    () => {
      setLoadApi(false);
    },
    () => {
      processFormApi();
    }
  );

  // Process Api
  const processFormApi = async () => {
    if (result.httpState === "SUCCESS") {
      setFormLoading(false);
      setLoadAuth(true);
      if (onFormSuccess) {
        onFormSuccess();
      }
      // Handle Success Here
    } else if (result.httpState === "ERROR") {
      setFormLoading(false);
      if (onFormFailure) {
        onFormFailure(result.data?.response?.data?.message || result.errorMsg);
        alert(result.data?.response?.data?.message || result.errorMsg);
      }
      //Handle Error Here
    }
  };

  return (
    <div>
      <>
        <form onSubmit={handleSubmit}>
          {/* Course Title */}
          <div className="w3-col">
            <div className="w3-col">
              <div className="w3-col w3-padding">
                <div>
                  <span>
                    Course Title <span className="w3-text-red">*</span>
                  </span>
                </div>
                <Input
                  required
                  name="courseTitle"
                  value={payLoad?.courseTitle}
                  onChange={handleInputChange}
                  type="text"
                  className="w3-col inputField"
                  placeholder="Course Title"
                  style={{ width: "100%", height: "50px" }}
                />
              </div>
            </div>
          </div>

          {/* Select Employee */}
          <div className="w3-col">
            <div className="w3-col w3-padding">
              <div>
                <span>
                  Assigned to <span className="w3-text-red">*</span>
                </span>
              </div>
              <Select
                id="country"
                value={payLoad?.employee || ""}
                onChange={(val) => {
                  handleSelectChange(val, "employee");
                }}
                showSearch
                className="w3-col w3-round-xlarge"
                style={{ width: "100%", height: "50px" }}
              >
                <Option value="">Select Employee</Option>
                <Option value="all">All Employee</Option>
              </Select>
            </div>
          </div>

          {/* Training Objective */}
          <div className="w3-col">
            <div className="w3-col">
              <div className="w3-col w3-padding">
                <div>
                  <span>Training Objective</span>
                </div>
                <TextArea
                  name="trainingObjective"
                  value={payLoad?.trainingObjective}
                  onChange={handleInputChange}
                  className="w3-col inputField"
                  placeholder="Description of Training Objective"
                  style={{ width: "100%", height: "80px", resize: "none" }}
                />
              </div>
            </div>
          </div>

          {/* Learning Outcome */}
          <div className="w3-col">
            <div className="w3-col">
              <div className="w3-col w3-padding">
                <div>
                  <span>Learning Outcome</span>
                </div>
                <TextArea
                  name="learningOutcome"
                  value={payLoad?.learningOutcome}
                  onChange={handleInputChange}
                  className="w3-col inputField"
                  placeholder="Description of Learning Outcome"
                  style={{ width: "100%", height: "80px", resize: "none" }}
                />
              </div>
            </div>
          </div>

          {/* Deadline */}
          <div className="w3-col">
            <div className="w3-col">
              <div className="w3-col w3-padding">
                <div>
                  <span>
                    Deadline <span className="w3-text-red">*</span>
                  </span>
                </div>
                <Input
                  required
                  name="deadLine"
                  value={payLoad?.deadLine}
                  onChange={handleInputChange}
                  type="date"
                  className="w3-col inputField"
                  placeholder="Deadline"
                  style={{ width: "100%", height: "50px" }}
                />
              </div>
            </div>
          </div>

          {/* Upload Materials */}
          <div className="w3-col">
            <div className="w3-col">
              <div className="w3-col w3-padding">
                <div>
                  <span>
                    Upload Materials <span className="w3-text-red">*</span>
                  </span>
                </div>
                <Input
                  required
                  name="uploadMaterials"
                  onChange={handleInputChange}
                  type="file"
                  className="w3-col inputField"
                  placeholder="Upload Materials"
                  style={{ width: "100%", height: "50px" }}
                />
              </div>
            </div>
          </div>

          {/* Button Here */}
          <div className="w3-col">
            <div className="w3-col w3-border-blue w3-padding w3-container w3-round-xlarge w3-right-align">
              <button
                className="w3-btn sitePrimaryButton w3-round-large"
                disabled={formLoading}
              >
                {!formLoading ? "Proceed" : <LoadingOutlined rev={undefined} />}
              </button>
            </div>
          </div>
        </form>
      </>
    </div>
  );
};

export default HrTrainingFormSetup;
