import { Button, notification, Pagination, Result, Tag } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { sampleApiCall } from "../../../../apiservice/authService";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import { convertToShortDate } from "../../../../utils/date.utils";
import { ILoadState } from "../../../../utils/loading.utils.";
import "../HrPerformanceManager.css";
type NotificationType = "success" | "info" | "warning" | "error";

type IHrReviewCyclesList = {
  externalFilter?: any;
  initialDefaultFilter?: any;
  hidePagination?: boolean;
};

export const HrReviewCyclesList: React.FC<IHrReviewCyclesList> = ({
  externalFilter,
  initialDefaultFilter,
  hidePagination = true,
}) => {
  const [reviewCyclesLoadState, setReviewCyclesLoadState] =
    useState<ILoadState>("loading");
  const [loadReviewCyclessData, setLoadReviewCyclessData] = useState(true);
  const [reviewCyclesDefaultFilter, setReviewCyclesDefaultFilter] = useState(
    initialDefaultFilter || { page: 1, perPage: 10 }
  );
  const [tableData, setTableData] = useState<any[]>([]);
  const [api, contextHolder] = notification.useNotification();

  // Pagination Constant/Variables
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const perPage = 10;

  // Use Effect to reload API when External Filter has changed
  useEffect(() => {
    if (externalFilter) {
      setCurrentPage(1);
      setReviewCyclesDefaultFilter({
        ...reviewCyclesDefaultFilter,
        ...externalFilter,
        page: 1,
      });
      setLoadReviewCyclessData(true);
      setReviewCyclesLoadState("loading");
    }
  }, [externalFilter]);

  // A custom hook to Load All ReviewCycless
  const ReviewCyclesDataResult = useFormatApiRequest(
    () => sampleApiCall(reviewCyclesDefaultFilter),
    loadReviewCyclessData,
    () => {
      setLoadReviewCyclessData(false);
    },
    () => {
      processReviewCyclessResult();
    }
  );

  // Process The Current ReviewCycles Data Result
  const processReviewCyclessResult = async () => {
    if (ReviewCyclesDataResult.httpState === "SUCCESS") {
      setTableData(ReviewCyclesDataResult.data?.data || [{}, {}, {}, {}]);
      setTotalItems(ReviewCyclesDataResult.data?.meta?.total || 1);
      setReviewCyclesLoadState("completed");
    } else if (ReviewCyclesDataResult.httpState === "ERROR") {
      setReviewCyclesLoadState("error");
    } else if (ReviewCyclesDataResult.httpState === "LOADING") {
      setReviewCyclesLoadState("loading");
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  // Table Configuration
  const columns: ColumnsType<any> = [
    {
      title: "Name",
      dataIndex: "applicantName",
      key: "applicantName",
      render: (text, records, index) => <span>{"Company Wide"}</span>,
    },
    {
      title: "Schedule",
      dataIndex: "schedule",
      key: "schedule",
      render: (text) => <span>{convertToShortDate("12-02-2024")}</span>,
    },
    {
      title: "Employee",
      dataIndex: "employee",
      key: "employee",
      render: (text, records, index) => <span>{"90"}</span>,
    },
    {
      title: "Status",
      dataIndex: "interviewStatus",
      key: "interviewStatus",
      render: (text, records, index) => (
        <Tag className="tagText" color={"green"}>
          {"Done"}
        </Tag>
      ),
    },
    {
      title: "Date",
      dataIndex: "dateModified",
      key: "dateModified",
      render: (text) => <span>{convertToShortDate(text)}</span>,
    },
  ];

  // Use to Control Pagination On Change Event
  const onPageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setReviewCyclesDefaultFilter({
      ...reviewCyclesDefaultFilter,
      page: page,
      perPage: pageSize,
    });
    setLoadReviewCyclessData(true);
  };

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}

      {/* " Show Loading Error" */}
      {reviewCyclesLoadState === "error" && (
        <div className="w3-col w3-padding-bottom">
          <Result
            status="500"
            title="Error"
            subTitle="Sorry, something went wrong, it could be a network Related error"
            extra={
              <Button
                onClick={() => setLoadReviewCyclessData(true)}
                type="primary"
              >
                Reload
              </Button>
            }
          />
        </div>
      )}

      {/* " Show Loading Table" */}
      {reviewCyclesLoadState !== "error" && (
        <>
          <div className="w3-col">
            <div className="w3-border w3-padding w3-round-xlarge">
              <Table
                className="w3-round-large"
                loading={reviewCyclesLoadState === "loading"}
                rowKey="id"
                size="small"
                columns={columns}
                dataSource={tableData}
                pagination={{ hideOnSinglePage: true }}
              />
            </div>
          </div>

          {!hidePagination && (
            <div className="w3-col w3-margin-top">
              <Pagination
                current={currentPage || 1}
                onChange={onPageChange}
                pageSize={perPage}
                total={totalItems}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default HrReviewCyclesList;
