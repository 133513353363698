import HrEmployeeEngagementSuggestionWrapper from "../../../components/HRUsersComp/hrEmployeeEngagement/HrEmployeeEngagementSuggestionWrapper";
import "../userPage.css";

export const HREmployeeEngagementSuggestionPage = () => {
  return (
    <>
      <HrEmployeeEngagementSuggestionWrapper></HrEmployeeEngagementSuggestionWrapper>
    </>
  );
};

export default HREmployeeEngagementSuggestionPage;
