import HrPayrollWrapper from "../../../components/HRUsersComp/hrAccounting/HrAccountingPayrollWrapper";
import "../userPage.css";

export const HRPayRollPage = () => {
  return (
    <>
      <HrPayrollWrapper></HrPayrollWrapper>
    </>
  );
};

export default HRPayRollPage;
