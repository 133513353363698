import { InboxOutlined, LoadingOutlined } from "@ant-design/icons";
import { Breadcrumb, Input, message, Select, Upload, UploadProps } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";
import { sampleApiCall } from "../../../../apiservice/authService";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import "../HrCloudLeaveManagement.css";
const { Option } = Select;

type IHrCloudLeaveManagementPolicyForm = {
  onFormSuccess?: () => void;
  onFormFailure?: (err?: string) => void;
};

export const HrCloudLeaveManagementPolicyForm: React.FC<
  IHrCloudLeaveManagementPolicyForm
> = ({ onFormFailure, onFormSuccess }) => {
  const [loadApi, setLoadApi] = useState(false);
  const [payLoad, setpayLoad] = useState<any>(null);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [showFormResult, setShowFormResult] = useState(false);
  const [loadAuth, setLoadAuth] = useState(false);

  const { Dragger } = Upload;

  const props: UploadProps = {
    name: "file",
    multiple: true,
    action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  // Use to collect Input change Change
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setpayLoad((values) => ({ ...values, [name]: value }));
  };

  const handleSelectChange = (value, name) => {
    setpayLoad((values) => ({ ...values, [name]: value }));
  };

  // Use to Submit Form
  const handleSubmit = (event: any) => {
    event.preventDefault();
    setLoadApi(true);
    setFormLoading(true);
  };

  // A custom hook to Process Form
  const result = useFormatApiRequest(
    () => sampleApiCall(payLoad),
    loadApi,
    () => {
      setLoadApi(false);
    },
    () => {
      processFormApi();
    }
  );

  // Process Api
  const processFormApi = async () => {
    if (result.httpState === "SUCCESS") {
      setFormLoading(false);
      setShowFormResult(true);
      setLoadAuth(true);
      if (onFormSuccess) {
        onFormSuccess();
      }
      // Handle Success Here
    } else if (result.httpState === "ERROR") {
      setFormLoading(false);
      if (onFormFailure) {
        onFormFailure(result.data?.response?.data?.message || result.errorMsg);
        alert(result.data?.response?.data?.message || result.errorMsg);
      }
      //Handle Error Here
    }
  };

  return (
    <div>
      <>
        <form onSubmit={handleSubmit}>
          {/* Forms Here */}
          <div className="w3-col w3-text-black w3-padding">
            <div>
              <Breadcrumb
                items={[
                  {
                    title: (
                      <span className="siteBorderPrimaryColor w3-large">
                        <Link to={"/hr"}>HRCLOUD</Link>
                      </span>
                    ),
                  },
                  {
                    title: (
                      <span className="siteBorderPrimaryColor w3-large">
                        <Link to={"/hr/leave-management"}>
                          Leave Management
                        </Link>
                      </span>
                    ),
                  },
                  {
                    title: (
                      <span className="siteBorderPrimaryColor w3-large">
                        Leave Policy
                      </span>
                    ),
                  },
                ]}
              />
              <br />
            </div>
          </div>

          {/* Leave Policy */}
          <div className="w3-col">
            <div className="w3-col w3-margin-bottom w3-padding">
              <div>
                <span>
                  Browse for File <span className="w3-text-red">*</span>
                </span>
              </div>
            </div>

            <div className="w3-col w3-margin-bottom w3-padding">
              <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Please Upload Company Leave Policy.
                </p>
              </Dragger>
            </div>

            <div className="w3-col w3-margin-bottom w3-padding">
              <div>
                <span>
                  <u>Preview File</u>
                </span>
              </div>
            </div>
          </div>

          {/* Button Here */}
          <div className="w3-col">
            <div className="w3-col w3-border-blue w3-padding w3-container w3-round-xlarge w3-right-align">
              <button
                className="w3-btn sitePrimaryButton w3-red w3-round-large"
                disabled={formLoading}
              >
                {!formLoading ? (
                  "Remove File"
                ) : (
                  <LoadingOutlined rev={undefined} />
                )}
              </button>
              &nbsp;
              <button
                className="w3-btn sitePrimaryButton w3-round-large"
                disabled={formLoading}
              >
                {!formLoading ? "Upload" : <LoadingOutlined rev={undefined} />}
              </button>
            </div>
          </div>
        </form>
      </>
    </div>
  );
};

export default HrCloudLeaveManagementPolicyForm;
