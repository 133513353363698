import { Calendar } from "antd";
import type { CalendarProps } from "antd";
import type { Dayjs } from "dayjs";
import "./HrCloudLeaveManagement.css";

export const HRCloudLeaveManagementCalenderComp = () => {
  const onPanelChange = (value: Dayjs, mode: CalendarProps<Dayjs>["mode"]) => {
    console.log(value.format("YYYY-MM-DD"), mode);
  };
  return (
    <>
      <div className="w3-col" style={{ minHeight: "400px" }}>
        <div className="w3-col l9 s12 m12" style={{ padding: "5px" }}>
          <div className="w3-border w3-round-xlarge w3-padding">
            <Calendar onPanelChange={onPanelChange} />
          </div>
        </div>

        <div className="w3-col l3 s12 m12" style={{ padding: "5px" }}>
          {/* Sick Leave */}
          <div className="w3-col sickLeaveCounterContainer w3-round-large w3-center w3-margin-bottom">
            <span className="w3-xxxlarge"> 1 </span>
            <br />
            <span> Sick Leave</span>
            <br />
            <br />
          </div>

          {/* Annual Leave */}
          <div className="w3-col annualLeaveCounterContainer w3-round-large w3-center w3-margin-bottom">
            <span className="w3-xxxlarge"> 2 </span>
            <br />
            <span> Annual Leave</span>
            <br />
            <br />
          </div>

          {/* Holiday */}
          <div className="w3-col holidayLeaveCounterContainer  w3-round-large w3-center w3-margin-bottom">
            <span className="w3-xxxlarge"> 2 </span>
            <br />
            <span> Holiday </span>
            <br />
            <br />
          </div>

          {/* Casual Leave */}
          <div className="w3-col casualLeaveCounterContainer w3-round-large w3-center w3-margin-bottom">
            <span className="w3-xxxlarge"> 2 </span>
            <br />
            <span> Casual Leave </span>
            <br />
            <br />
          </div>
        </div>
      </div>
    </>
  );
};

export default HRCloudLeaveManagementCalenderComp;
