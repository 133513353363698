import { LoadingOutlined } from "@ant-design/icons";
import { Breadcrumb, Input, Radio, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useState } from "react";
import { Link } from "react-router-dom";
import { sampleApiCall } from "../../../../apiservice/authService";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import "../HrCloudLeaveManagement.css";
const { Option } = Select;

type IHrCloudLeaveManagementRequestForm = {
  onFormSuccess?: () => void;
  onFormFailure?: (err?: string) => void;
};

export const HrCloudLeaveManagementRequestForm: React.FC<
  IHrCloudLeaveManagementRequestForm
> = ({ onFormFailure, onFormSuccess }) => {
  const [loadApi, setLoadApi] = useState(false);
  const [payLoad, setpayLoad] = useState<any>(null);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [loadAuth, setLoadAuth] = useState(false);

  // Use to collect Input change Change
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setpayLoad((values) => ({ ...values, [name]: value }));
  };

  const handleSelectChange = (value, name) => {
    setpayLoad((values) => ({ ...values, [name]: value }));
  };

  // Use to Submit Form
  const handleSubmit = (event: any) => {
    event.preventDefault();
    setLoadApi(true);
    setFormLoading(true);
  };

  // A custom hook to Process Form
  const result = useFormatApiRequest(
    () => sampleApiCall(payLoad),
    loadApi,
    () => {
      setLoadApi(false);
    },
    () => {
      processFormApi();
    }
  );

  // Process Api
  const processFormApi = async () => {
    if (result.httpState === "SUCCESS") {
      setFormLoading(false);
      setLoadAuth(true);
      if (onFormSuccess) {
        onFormSuccess();
      }
      // Handle Success Here
    } else if (result.httpState === "ERROR") {
      setFormLoading(false);
      if (onFormFailure) {
        onFormFailure(result.data?.response?.data?.message || result.errorMsg);
        alert(result.data?.response?.data?.message || result.errorMsg);
      }
      //Handle Error Here
    }
  };

  return (
    <div>
      <>
        <form onSubmit={handleSubmit}>
          {/* Forms Here */}
          <div className="w3-col w3-text-black w3-padding">
            <div>
              <Breadcrumb
                items={[
                  {
                    title: (
                      <span className="siteBorderPrimaryColor w3-large">
                        <Link to={"/hr"}>HRCLOUD</Link>
                      </span>
                    ),
                  },
                  {
                    title: (
                      <span className="siteBorderPrimaryColor w3-large">
                        <Link to={"/hr/leave-management"}>
                          Leave Management
                        </Link>
                      </span>
                    ),
                  },
                  {
                    title: (
                      <span className="siteBorderPrimaryColor w3-large">
                        Leave Request Form
                      </span>
                    ),
                  },
                ]}
              />
              <br /> <br /> <br />
            </div>

            <h3 className="myfont w3-border-bottom">
              <b>EMPLOYEE LEAVE REQUEST FORM </b>
            </h3>
          </div>

          {/* Select Employee */}
          <div className="w3-col">
            <div className="w3-col w3-margin-bottom s12 l6 w3-padding">
              <div>
                <span>
                  Select Employee <span className="w3-text-red">*</span>
                </span>
              </div>
              <Select
                id="country"
                value={payLoad?.employee || ""}
                onChange={(val) => {
                  handleSelectChange(val, "employee");
                }}
                showSearch
                className="w3-col w3-round-xlarge"
                style={{ width: "100%", height: "50px" }}
              >
                <Option value="">Select Employee</Option>
                <Option value="all">All Employee</Option>
              </Select>
            </div>
          </div>

          <div className="w3-col">
            <div className="w3-col w3-margin-bottom w3-margin-top w3-padding">
              <div>
                <span>
                  Leave Type <span className="w3-text-red">*</span>
                </span>
              </div>
              <Radio.Group
                className="w3-col"
                name="type"
                onChange={handleInputChange}
                value={payLoad?.type}
              >
                <div className="w3-col l4 s6 m6" style={{ padding: "5px" }}>
                  <div className="inputRadio">
                    <Radio value={"annualLeave"}>Annual Leave</Radio>
                  </div>
                </div>

                <div className="w3-col l4 s6 m6" style={{ padding: "5px" }}>
                  <div className="inputRadio">
                    <Radio value={"funeralLeave"}>Funeral Leave</Radio>
                  </div>
                </div>

                <div className="w3-col l4 s6 m6" style={{ padding: "5px" }}>
                  <div className="inputRadio">
                    <Radio value={"vacation"}>Vacation</Radio>
                  </div>
                </div>

                <div className="w3-col l4 s6 m6" style={{ padding: "5px" }}>
                  <div className="inputRadio">
                    <Radio value={"sickLeave"}>Sick Leave</Radio>
                  </div>
                </div>

                <div className="w3-col l4 s6 m6" style={{ padding: "5px" }}>
                  <div className="inputRadio">
                    <Radio value={"maternity"}>Maternity Leave</Radio>
                  </div>
                </div>

                <div className="w3-col l4 s6 m6" style={{ padding: "5px" }}>
                  <div className="inputRadio">
                    <Radio value={"medical"}>Medical Leave</Radio>
                  </div>
                </div>

                <div className="w3-col l4 s6 m6" style={{ padding: "5px" }}>
                  <div className="inputRadio">
                    <Radio value={"other"}>Other</Radio>
                  </div>
                </div>
              </Radio.Group>
            </div>
          </div>

          <div className="w3-col">
            <div className="w3-col w3-margin-bottom w3-margin-top">
              <div className="w3-col w3-margin-bottom w3-padding">
                <span>Leave Duration</span>
              </div>

              <div className="w3-col w3-margin-bottom s12 l6 m6 w3-padding">
                <div>
                  <span>
                    Start Date <span className="w3-text-red">*</span>
                  </span>
                </div>
                <Input
                  required
                  name="startDate"
                  value={payLoad?.startDate}
                  onChange={handleInputChange}
                  type={"datetime-local"}
                  className="w3-col inputField"
                  placeholder="Start Date"
                  style={{ width: "100%", height: "50px" }}
                />
              </div>

              <div className="w3-col w3-margin-bottom s12 l6 m6 w3-padding">
                <div>
                  <span>
                    End Date <span className="w3-text-red">*</span>
                  </span>
                </div>
                <Input
                  required
                  name="endDate"
                  value={payLoad?.ednDate}
                  onChange={handleInputChange}
                  type={"datetime-local"}
                  className="w3-col inputField"
                  placeholder="End Date"
                  style={{ width: "100%", height: "50px" }}
                />
              </div>
            </div>
          </div>

          <div className="w3-col">
            <div className="w3-col w3-margin-bottom w3-margin-top">
              <div className="w3-col w3-margin-bottom w3-padding">
                <div>
                  <span>
                    Description <span className="w3-text-red">*</span>
                  </span>
                </div>
                <TextArea
                  required
                  name="startDate"
                  value={payLoad?.startDate}
                  onChange={handleInputChange}
                  className="w3-col inputField"
                  placeholder="Description Of Leave"
                  style={{ width: "100%", height: "200px", resize: "none" }}
                />
              </div>
            </div>
          </div>

          {/* Button Here */}
          <div className="w3-col">
            <div className="w3-col w3-border-blue w3-padding w3-container w3-round-xlarge w3-right-align">
              <button
                className="w3-btn sitePrimaryButton w3-red w3-round-large"
                disabled={formLoading}
              >
                {!formLoading ? "Cancel" : <LoadingOutlined rev={undefined} />}
              </button>
              &nbsp;
              <button
                className="w3-btn sitePrimaryButton w3-round-large"
                disabled={formLoading}
              >
                {!formLoading ? (
                  "Save Changes"
                ) : (
                  <LoadingOutlined rev={undefined} />
                )}
              </button>
            </div>
          </div>
        </form>
      </>
    </div>
  );
};

export default HrCloudLeaveManagementRequestForm;
