import { Button, notification, Pagination, Result, Tag } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { sampleApiCall } from "../../../../apiservice/authService";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import { convertToShortDate } from "../../../../utils/date.utils";
import { ILoadState } from "../../../../utils/loading.utils.";
import "../HrAccounting.css";
type NotificationType = "success" | "info" | "warning" | "error";

type IHrAccoutingPaymentList = {
  externalFilter?: any;
  initialDefaultFilter?: any;
  hidePagination?: boolean;
};

export const HrAccoutingPaymentList: React.FC<IHrAccoutingPaymentList> = ({
  externalFilter,
  initialDefaultFilter,
  hidePagination = true,
}) => {
  const [paymentListLoadState, setPaymentListLoadState] =
    useState<ILoadState>("loading");
  const [loadpaymentListsData, setLoadPaymentListsData] = useState(true);
  const [paymentListDefaultFilter, setPaymentListDefaultFilter] = useState(
    initialDefaultFilter || { page: 1, perPage: 10 }
  );
  const [tableData, setTableData] = useState<any[]>([]);
  const [api, contextHolder] = notification.useNotification();

  // Pagination Constant/Variables
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const perPage = 10;

  // Use Effect to reload API when External Filter has changed
  useEffect(() => {
    if (externalFilter) {
      setCurrentPage(1);
      setPaymentListDefaultFilter({
        ...paymentListDefaultFilter,
        ...externalFilter,
        page: 1,
      });
      setLoadPaymentListsData(true);
      setPaymentListLoadState("loading");
    }
  }, [externalFilter]);

  // A custom hook to Load All paymentLists
  const paymentListDataResult = useFormatApiRequest(
    () => sampleApiCall(paymentListDefaultFilter),
    loadpaymentListsData,
    () => {
      setLoadPaymentListsData(false);
    },
    () => {
      processpaymentListsResult();
    }
  );

  // Process The Current paymentList Data Result
  const processpaymentListsResult = async () => {
    if (paymentListDataResult.httpState === "SUCCESS") {
      setTableData(paymentListDataResult.data?.data || [{}, {}, {}, {}]);
      setTotalItems(paymentListDataResult.data?.meta?.total || 1);
      setPaymentListLoadState("completed");
    } else if (paymentListDataResult.httpState === "ERROR") {
      setPaymentListLoadState("error");
    } else if (paymentListDataResult.httpState === "LOADING") {
      setPaymentListLoadState("loading");
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  // Table Configuration
  const columns: ColumnsType<any> = [
    {
      title: "Employee Name",
      dataIndex: "employeeName",
      key: "employeeName",
      render: (text, records, index) => <span>{"James Manager"}</span>,
    },
    {
      title: "Work Hour",
      dataIndex: "workHour",
      key: "workHour",
      render: (text, records, index) => <span> Full Time </span>,
    },
    {
      title: "Salary",
      dataIndex: "salary",
      key: "salary",
      render: (text, records, index) => <span>{"$250"}</span>,
    },

    {
      title: "Additional Earning",
      dataIndex: "additionalEarning",
      key: "additionalEarning",
      render: (text, records, index) => <span>{"$50"}</span>,
    },

    {
      title: "Deduction",
      dataIndex: "deduction",
      key: "deduction",
      render: (text, records, index) => <span>{"$50"}</span>,
    },

    {
      title: "Gross Pay",
      dataIndex: "grossPay",
      key: "grossPay",
      render: (text, records, index) => <span>{"$250"}</span>,
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, records, index) => (
        <Tag className="tagText" color={"green"}>
          {"Approved"}
        </Tag>
      ),
    },
    {
      title: "Date",
      dataIndex: "dateModified",
      key: "dateModified",
      render: (text) => <span>{convertToShortDate(text)}</span>,
    },
    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   key: "action",
    //   width: 170,
    //   fixed: "right",
    //   render: (text, records, index) => (
    //     <>
    //       <div className="w3-center">
    //         <span className="siteTableBtn w3-tiny w3-green w3-round">
    //           Approve
    //         </span>
    //         &nbsp;
    //         <span className="siteTableBtn w3-tiny w3-red w3-round">Reject</span>
    //       </div>
    //     </>
    //   ),
    // },
  ];

  // Use to Control Pagination On Change Event
  const onPageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPaymentListDefaultFilter({
      ...paymentListDefaultFilter,
      page: page,
      perPage: pageSize,
    });
    setLoadPaymentListsData(true);
  };

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}

      {/* " Show Loading Error" */}
      {paymentListLoadState === "error" && (
        <div className="w3-col w3-padding-bottom">
          <Result
            status="500"
            title="Error"
            subTitle="Sorry, something went wrong, it could be a network Related error"
            extra={
              <Button
                onClick={() => setLoadPaymentListsData(true)}
                type="primary"
              >
                Reload
              </Button>
            }
          />
        </div>
      )}

      {/* " Show Loading Table" */}
      {paymentListLoadState !== "error" && (
        <>
          <div className="w3-col">
            <div className="w3-border w3-padding w3-round-xlarge">
              <Table
                className="w3-round-large"
                loading={paymentListLoadState === "loading"}
                rowKey="id"
                size="small"
                columns={columns}
                dataSource={tableData}
                scroll={{ x: 950 }}
                pagination={{ hideOnSinglePage: true }}
              />
            </div>
          </div>

          {!hidePagination && (
            <div className="w3-col w3-margin-top">
              <Pagination
                current={currentPage || 1}
                onChange={onPageChange}
                pageSize={perPage}
                total={totalItems}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default HrAccoutingPaymentList;
