import { Button, notification, Pagination, Result } from "antd";
import { useEffect, useState } from "react";
import { sampleApiCall } from "../../../../apiservice/authService";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import { ILoadState } from "../../../../utils/loading.utils.";
import "../HrTimeAndAttedance.css";
type NotificationType = "success" | "info" | "warning" | "error";

type IHrAttendanceEmployeeList = {
  externalFilter?: any;
  initialDefaultFilter?: any;
  hidePagination?: boolean;
};

export const HrAttendanceEmployeeList: React.FC<IHrAttendanceEmployeeList> = ({
  externalFilter,
  initialDefaultFilter,
  hidePagination = true,
}) => {
  const [employeeListLoadState, setEmployeeListLoadState] =
    useState<ILoadState>("loading");
  const [loademployeeListsData, setLoadEmployeeListsData] = useState(true);
  const [employeeListDefaultFilter, setEmployeeListDefaultFilter] = useState(
    initialDefaultFilter || { page: 1, perPage: 10 }
  );
  const [tableData, setTableData] = useState<any[]>([]);
  const [api, contextHolder] = notification.useNotification();

  // Pagination Constant/Variables
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const perPage = 10;

  // Use Effect to reload API when External Filter has changed
  useEffect(() => {
    if (externalFilter) {
      setCurrentPage(1);
      setEmployeeListDefaultFilter({
        ...employeeListDefaultFilter,
        ...externalFilter,
        page: 1,
      });
      setLoadEmployeeListsData(true);
      setEmployeeListLoadState("loading");
    }
  }, [externalFilter]);

  // A custom hook to Load All employeeLists
  const employeeListDataResult = useFormatApiRequest(
    () => sampleApiCall(employeeListDefaultFilter),
    loademployeeListsData,
    () => {
      setLoadEmployeeListsData(false);
    },
    () => {
      processemployeeListsResult();
    }
  );

  // Process The Current employeeList Data Result
  const processemployeeListsResult = async () => {
    if (employeeListDataResult.httpState === "SUCCESS") {
      setTableData(
        employeeListDataResult.data?.data || [{}, {}, {}, {}, {}, {}, {}, {}]
      );
      setTotalItems(employeeListDataResult.data?.meta?.total || 1);
      setEmployeeListLoadState("completed");
    } else if (employeeListDataResult.httpState === "ERROR") {
      setEmployeeListLoadState("error");
    } else if (employeeListDataResult.httpState === "LOADING") {
      setEmployeeListLoadState("loading");
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  // Use to Control Pagination On Change Event
  const onPageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setEmployeeListDefaultFilter({
      ...employeeListDefaultFilter,
      page: page,
      perPage: pageSize,
    });
    setLoadEmployeeListsData(true);
  };

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}

      {/* " Show Loading Error" */}
      {employeeListLoadState === "error" && (
        <div className="w3-col w3-padding-bottom">
          <Result
            status="500"
            title="Error"
            subTitle="Sorry, something went wrong, it could be a network Related error"
            extra={
              <Button
                onClick={() => setLoadEmployeeListsData(true)}
                type="primary"
              >
                Reload
              </Button>
            }
          />
        </div>
      )}

      {/* " Show Loading Table" */}
      {employeeListLoadState !== "error" && (
        <>
          <div className="w3-col">

            {/* Custom Cards */}
            {tableData.map((item: any, index: number) => (
              <div className="w3-col l3 s6 m4" style={{ padding: "10px" }}>
                <div className="w3-col w3-card w3-padding w3-round-xlarge w3-center">
                  <img
                    className="w3-circle"
                    style={{ width: "80px", maxWidth: "100%" }}
                    alt="logo"
                    src={`${
                      process.env.PUBLIC_URL + "/images/auth/profileSample.svg"
                    }`}
                  />
                  <p className="w3-text-black myfont1 w3-small w3-margin-top">
                    {" "}
                    Juliet Abraham{" "}
                  </p>

                  <p className="w3-text-black myfont1 w3-margin-top">
                    <button className="w3-button w3-round-xlarge w3-small sitePrimaryButton">
                      Clock In
                    </button>
                  </p>
                </div>
              </div>
            ))}

          </div>

          {!hidePagination && (
            <div className="w3-col w3-margin-top">
              <Pagination
                current={currentPage || 1}
                onChange={onPageChange}
                pageSize={perPage}
                total={totalItems}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default HrAttendanceEmployeeList;
