import HrAccountingWrapper from "../../../components/HRUsersComp/hrAccounting/HrAccountingWrapper";
import "../userPage.css";

export const HRAccountPage = () => {
  return (
    <>
      <HrAccountingWrapper></HrAccountingWrapper>
    </>
  );
};

export default HRAccountPage;
