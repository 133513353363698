import "../../App.css";
import { Routes, Route } from "react-router-dom";
import Nopage from "../Nopage/Nopage";
import UserLogout from "./UserLogout/UserLogout";
import HRCloudDashboardPage from "./HRCloudDashboardPage/HRCloudDashboardPage";
import HRLeaveManagementPage from "./HRLeaveManagementPage/HRLeaveManagementPage";
import { HREmployeePortalPage } from "./HREmployeePortalPage/HREmployeePortalPage";
import HRTimeAndAttendance from "./HRTimeAndAttendance/HRTimeAndAttendance";
import HRTrainingPage from "./HRTrainingPage/HRTrainingPage";
import HRAnalyticsPage from "./HRAnalyticsPage/HRAnalyticsPage";
import HRRecruitmentPage from "./HRRecruitmentPage/HRRecruitmentPage";
import HRPerformancePage from "./HRPerformancePage/HRPerformancePage";
import HRPayRollPage from "./HRPayRollPage/HRPayRollPage";
import HREmployeeEngagementPage from "./HREmployeeEngagementPage/HREmployeeEngagementPage";
import HRAccountPage from "./HRAccountPage/HRAccountPage";
import MoreServices from "./MoreServicePage/MoreServicesPage";
import UserLayout from "./UserLayout/UserLayout";
import HREmployeeManagmentPage from "./HREmployeeManagmentPage/HREmployeeManagmentPage";
import HrEmployeeDetails from "../../components/HRUsersComp/hrEmployeeManagementComp/hrEmployeeDetails";
import HrCloudLeaveManagementPolicyForm from "../../components/HRUsersComp/hrCloudLeaveManagement/hrLeaveForms/HrCloudLeaveManagementPolicyForm";
import HrCloudLeaveManagementRequestForm from "../../components/HRUsersComp/hrCloudLeaveManagement/hrLeaveForms/HrCloudLeaveManagementRequestForm";
import HRAccountBenefitMangerPage from "./HRAccountBenefitMangerPage/HRAccountBenefitMangerPage";
import HRAccountTaxMangerPage from "./HRAccountTaxMangerPage/HRAccountTaxMangerPage";
import HREmployeeEngagementSuggestionPage from "./HREmployeeEngagementSuggestionPage/HREmployeeEngagementSuggestionPage";
import HREmployeeEngagementSurveyPage from "./HREmployeeEngagementSurveyPage/HREmployeeEngagementSurveyPage";

const UserPagesRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<UserLayout />}>
        <Route index element={<HRCloudDashboardPage />} />
        <Route path="/dashboard" element={<HRCloudDashboardPage />} />
        <Route
          path="/employee-management"
          element={<HREmployeeManagmentPage />}
        />
        <Route
          path="/employee-management/:id/:name"
          element={<HrEmployeeDetails />}
        />

        <Route path="/leave-management" element={<HRLeaveManagementPage />} />
        <Route
          path="/leave-management/leaveRequestForm"
          element={<HrCloudLeaveManagementRequestForm />}
        />
        <Route
          path="/leave-management/leavePolicyForm"
          element={<HrCloudLeaveManagementPolicyForm />}
        />

        <Route path="/employee-portal" element={<HREmployeePortalPage />} />
        <Route path="/time-attendance" element={<HRTimeAndAttendance />} />

        <Route path="/training" element={<HRTrainingPage />} />
        <Route path="/analytics" element={<HRAnalyticsPage />} />
        <Route path="/recruitment" element={<HRRecruitmentPage />} />
        <Route path="/performance" element={<HRPerformancePage />} />
        <Route path="/payroll" element={<HRPayRollPage />} />

        <Route
          path="/employee-engagement"
          element={<HREmployeeEngagementPage />}
        />

        <Route
          path="/employee-engagement-suggestion"
          element={<HREmployeeEngagementSuggestionPage />}
        />

        <Route
          path="/employee-engagement-survey"
          element={<HREmployeeEngagementSurveyPage />}
        />

        <Route path="/accountant" element={<HRAccountPage />} />
        <Route
          path="/accountantBenefitManager"
          element={<HRAccountBenefitMangerPage />}
        />
        <Route
          path="/accountantTaxManager"
          element={<HRAccountTaxMangerPage />}
        />

        <Route path="/more-services" element={<MoreServices />} />
        <Route path="/logout" element={<UserLogout />} />
        <Route path="*" element={<Nopage />} />
      </Route>
    </Routes>
  );
};

export default UserPagesRoutes;
