import HRRecentRecruitmentList from "../hrEmployeeRecruitment/HrRecruitmentLists/hrEmployeeRecruitmentList";
import HRCloudDashboardMenu from "./HrClodDashboardMenu";
import "./HrCloudDashboard.css";

export const HRCloudDashboardComp = () => {
  return (
    <>
      <div className="w3-col" style={{ minHeight: "400px" }}>
        <div className="w3-col" style={{ marginTop: "20px" }}>
          <div className="w3-container">
            <div className="w3-content">
              <div className="w3-col w3-margin-bottom">
                <h2 className="siteBorderPrimaryColor w3-xlarge">HR CLOUD</h2>
                <br />
                <p>
                  Borem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                  vulputate libero et velit interdum, ac aliquet odio mattis.
                  <br />
                  Class aptent taciti sociosqu ad litora torquent per conubia
                  nostra, per inceptos himenaeos.
                </p>
              </div>

              <HRCloudDashboardMenu></HRCloudDashboardMenu>

              <HRRecentRecruitmentList></HRRecentRecruitmentList>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HRCloudDashboardComp;
