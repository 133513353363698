import HrAccountingTaxWrapper from "../../../components/HRUsersComp/hrAccounting/HrAccountingTaxWrapper";
import "../userPage.css";

export const HRAccountTaxMangerPage = () => {
  return (
    <>
      <HrAccountingTaxWrapper></HrAccountingTaxWrapper>
    </>
  );
};

export default HRAccountTaxMangerPage;
