import HrPerformanceManagerWrapper from "../../../components/HRUsersComp/hrPerformanceManager/HrPerformanceManagerWrapper";
import "../userPage.css";

export const HRPerformancePage = () => {
  return (
    <>
      <HrPerformanceManagerWrapper></HrPerformanceManagerWrapper>
    </>
  );
};

export default HRPerformancePage;
