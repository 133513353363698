import HrEmployeeEngagementSurveyWrapper from "../../../components/HRUsersComp/hrEmployeeEngagement/HrEmployeeEngagementSurveyWrapper";
import "../userPage.css";

export const HREmployeeEngagementSurveyPage = () => {
  return (
    <>
      <HrEmployeeEngagementSurveyWrapper></HrEmployeeEngagementSurveyWrapper>
    </>
  );
};

export default HREmployeeEngagementSurveyPage;
