import { Button, notification, Pagination, Result, Tag } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { sampleApiCall } from "../../../../apiservice/authService";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import { convertToShortDate } from "../../../../utils/date.utils";
import { ILoadState } from "../../../../utils/loading.utils.";
import "../HrTimeAndAttedance.css";
type NotificationType = "success" | "info" | "warning" | "error";

type IHrTimeSheetList = {
  externalFilter?: any;
  initialDefaultFilter?: any;
  hidePagination?: boolean;
};

export const HrTimeSheetList: React.FC<IHrTimeSheetList> = ({
  externalFilter,
  initialDefaultFilter,
  hidePagination = true,
}) => {
  const [timeSheetListLoadState, setTimeSheetListLoadState] =
    useState<ILoadState>("loading");
  const [loadtimeSheetListsData, setLoadTimeSheetListsData] = useState(true);
  const [timeSheetListDefaultFilter, setTimeSheetListDefaultFilter] = useState(
    initialDefaultFilter || { page: 1, perPage: 10 }
  );
  const [tableData, setTableData] = useState<any[]>([]);
  const [api, contextHolder] = notification.useNotification();

  // Pagination Constant/Variables
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const perPage = 10;

  // Use Effect to reload API when External Filter has changed
  useEffect(() => {
    if (externalFilter) {
      setCurrentPage(1);
      setTimeSheetListDefaultFilter({
        ...timeSheetListDefaultFilter,
        ...externalFilter,
        page: 1,
      });
      setLoadTimeSheetListsData(true);
      setTimeSheetListLoadState("loading");
    }
  }, [externalFilter]);

  // A custom hook to Load All timeSheetLists
  const timeSheetListDataResult = useFormatApiRequest(
    () => sampleApiCall(timeSheetListDefaultFilter),
    loadtimeSheetListsData,
    () => {
      setLoadTimeSheetListsData(false);
    },
    () => {
      processtimeSheetListsResult();
    }
  );

  // Process The Current timeSheetList Data Result
  const processtimeSheetListsResult = async () => {
    if (timeSheetListDataResult.httpState === "SUCCESS") {
      setTableData(timeSheetListDataResult.data?.data || [{}, {}, {}, {}]);
      setTotalItems(timeSheetListDataResult.data?.meta?.total || 1);
      setTimeSheetListLoadState("completed");
    } else if (timeSheetListDataResult.httpState === "ERROR") {
      setTimeSheetListLoadState("error");
    } else if (timeSheetListDataResult.httpState === "LOADING") {
      setTimeSheetListLoadState("loading");
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  // Table Configuration
  const columns: ColumnsType<any> = [
    {
      title: "Employee Name",
      dataIndex: "employeeName",
      key: "employeeName",
      render: (text, records, index) => <span>{"James Manager"}</span>,
    },
    {
      title: "Team",
      dataIndex: "workHour",
      key: "workHour",
      render: (text, records, index) => <span> {"Dev Team"} </span>,
    },
    {
      title: "Clock In",
      dataIndex: "clockIn",
      key: "clockIn",
      render: (text, records, index) => <span>{"8:10am"}</span>,
    },

    {
      title: "Clock Out",
      dataIndex: "clockOut",
      key: "clockOut",
      render: (text, records, index) => <span>{"9:45am"}</span>,
    },

    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      render: (text, records, index) => <span>{"1hr : 35 Min"}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, records, index) => (
        <Tag className="tagText" color={"green"}>
          {"Normal"}
        </Tag>
      ),
    },
    {
      title: "Date",
      dataIndex: "dateModified",
      key: "dateModified",
      render: (text) => <span>{convertToShortDate(text)}</span>,
    },
    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   key: "action",
    //   width: 170,
    //   fixed: "right",
    //   render: (text, records, index) => (
    //     <>
    //       <div className="w3-center">
    //         <span className="siteTableBtn w3-tiny w3-green w3-round">
    //           Approve
    //         </span>
    //         &nbsp;
    //         <span className="siteTableBtn w3-tiny w3-red w3-round">Reject</span>
    //       </div>
    //     </>
    //   ),
    // },
  ];

  // Use to Control Pagination On Change Event
  const onPageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setTimeSheetListDefaultFilter({
      ...timeSheetListDefaultFilter,
      page: page,
      perPage: pageSize,
    });
    setLoadTimeSheetListsData(true);
  };

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}

      {/* " Show Loading Error" */}
      {timeSheetListLoadState === "error" && (
        <div className="w3-col w3-padding-bottom">
          <Result
            status="500"
            title="Error"
            subTitle="Sorry, something went wrong, it could be a network Related error"
            extra={
              <Button
                onClick={() => setLoadTimeSheetListsData(true)}
                type="primary"
              >
                Reload
              </Button>
            }
          />
        </div>
      )}

      {/* " Show Loading Table" */}
      {timeSheetListLoadState !== "error" && (
        <>
          <div className="w3-col">
            <div className="w3-border w3-padding w3-round-xlarge">
              <Table
                className="w3-round-large"
                loading={timeSheetListLoadState === "loading"}
                rowKey="id"
                size="small"
                columns={columns}
                dataSource={tableData}
                scroll={{ x: 950 }}
                pagination={{ hideOnSinglePage: true }}
              />
            </div>
          </div>

          {!hidePagination && (
            <div className="w3-col w3-margin-top">
              <Pagination
                current={currentPage || 1}
                onChange={onPageChange}
                pageSize={perPage}
                total={totalItems}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default HrTimeSheetList;
