import "./HrEmployeePortal.css";

export const HREmployeePortalCard = () => {
  type IMenuType = {
    icon: string | any;
    url: string;
    title: string;
    onClick?: () => void;
  };

  const menu: IMenuType[] = [
    {
      icon: (
        <img
          style={{ width: "20px", maxWidth: "100%" }}
          alt="logo"
          src={process.env.PUBLIC_URL + "/images/hrcloud/iconemployeeDb.svg"}
        />
      ),
      url: "/",
      title: "My Profile",
    },
    {
      icon: (
        <img
          style={{ width: "20px", maxWidth: "100%" }}
          alt="logo"
          src={
            process.env.PUBLIC_URL + "/images/hrcloud/iconleavemanagement.svg"
          }
        />
      ),
      url: "/",
      title: "Apply For Leave",
    },
    {
      icon: (
        <img
          style={{ width: "20px", maxWidth: "100%" }}
          alt="logo"
          src={process.env.PUBLIC_URL + "/images/hrcloud/iconperformance.svg"}
        />
      ),
      url: "/",
      title: "My Performance",
    },
  ];

  return (
    <>
      <div className="w3-col" style={{ minHeight: "400px" }}>
        {/* Profile Card */}
        <div className="w3-col">
          <div className="w3-col l2 s2">
            <div className="profile-picture">
              <img
                className="w3-circle"
                src={`${
                  process.env.PUBLIC_URL + "/images/auth/profileSample.svg"
                }`}
                alt=""
              />
            </div>
          </div>
          <div className="w3-col l10 s10 w3-padding">
            <div className="profile-background">
              <img src="/images/shared-profile/profile-background.svg" alt="" />
            </div>

            <div className="w3-padding">
              {/* Name and Position */}
              <div className="w3-col l6 s12 m6 w3-margin-bottom">
                <span className="profileName">
                  <b>{"Kenneth Promise"}</b>
                </span>
                <br />
                <span className="w3-small profilePositionText">
                  {"Software Developer"}
                </span>
                <br />
                <span className="w3-small profilePositionText">
                  {"Development Team"}
                </span>
              </div>

              {/* Name and Position */}
              <div className="w3-col l6 s12 m6 w3-margin-bottom">
                <span className="profileName">
                  <b>{"Status : Full Time"}</b>
                </span>
                <br />
                <span className="w3-small profilePositionText">
                  {"Employed For : For 5 years"}
                </span>
                <br />
                <span className="w3-small profilePositionText">
                  {"Age : 35 years"}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Menu */}
        <div className="w3-col" style={{ marginTop: "20px" }}>
          {menu.map((item: IMenuType, index: number) => (
            <div className="w3-col l4 s12 m6" style={{ padding: "5px" }}>
              <div className="w3-col sitePrimaryBorderButton w3-border w3-round-xlarge w3-padding">
                {item.icon} {item.title}
              </div>
            </div>
          ))}
        </div>

        {/* Menu  Two */}
        <div className="w3-col" style={{ marginTop: "40px" }}>
          {/* Time Sheet */}
          <div className="w3-col l4 s12 m6" style={{ padding: "5px" }}>
            <div className="w3-col w3-border w3-round-large">
              <div className="w3-col w3-border w3-round-large w3-center w3-padding">
                Active Timesheet
              </div>
              <div className="w3-col  w3-center w3-padding">
                You have updated your timesheet
                <br />
                <br />
                <button className="sitePrimaryBorderButton w3-round w3-btn">
                  Add Timesheet
                </button>{" "}
                <br />
                <br />
              </div>
            </div>
          </div>

          {/* Active Clock In */}
          <div className="w3-col l4 s12 m6" style={{ padding: "5px" }}>
            <div className="w3-col w3-border w3-round-large">
              <div className="w3-col w3-border w3-round-large  w3-center w3-padding">
                Active Clock In
              </div>
              <div className="w3-col  w3-center w3-padding">
                You have Clocked In Today
                <br />
                <br />
                <br />
                <br />
                <br />
              </div>
            </div>
          </div>

          {/* Active Clock Out */}
          <div className="w3-col l4 s12 m6" style={{ padding: "5px" }}>
            <div className="w3-col w3-border w3-round-large">
              <div className="w3-col w3-border w3-round-large  w3-center w3-padding">
                Active Clock Out
              </div>
              <div className="w3-col  w3-center w3-padding">
                You have an active session today
                <br />
                <br />
                <button className="sitePrimaryBorderButton w3-round w3-btn">
                  Clock Out
                </button>{" "}
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HREmployeePortalCard;
