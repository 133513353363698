import { Button, Empty, notification, Result, Spin } from "antd";
import { useEffect, useState } from "react";
import { sampleApiCall } from "../../../apiservice/authService";
import useFormatApiRequest from "../../../hooks/formatApiRequest";
import useAuth from "../../../hooks/useAuth";
import { useAppDispatch } from "../../../Redux/reduxCustomHook";
import { ILoadState } from "../../../utils/loading.utils.";
import GeneralCharts from "../../Sharedcomponents/Charts/GeneralCharts";
import "./HrCloudLeaveManagement.css";
type NotificationType = "success" | "info" | "warning" | "error";

type IHrCloudChart = {
  externalFilter?: any;
  initialDefaultFilter?: any;
};

export const HrCloudChart: React.FC<IHrCloudChart> = ({
  externalFilter,
  initialDefaultFilter,
}) => {
  const [hrCloudChartdLoadState, setHrCloudChartLoadState] =
    useState<ILoadState>("loading");
  const [loadHrCloudChartData, setLoadHrCloudChartData] = useState(true);
  const [hrCloudChartdDefaultFilter, setHrCloudChartDefaultFilter] = useState(
    initialDefaultFilter || {}
  );
  const [tableData, setTableData] = useState<any[]>([]);
  const [api, contextHolder] = notification.useNotification();
  const dispatch = useAppDispatch();

  const [loadAuth, setLoadAuth] = useState(false);

  // Use to refresh the Auth State
  const authState = useAuth(loadAuth, () => {
    setLoadAuth(false);
  });

  // Use Effect to reload API when External Filter has changed
  useEffect(() => {
    if (externalFilter) {
      setHrCloudChartDefaultFilter({
        ...hrCloudChartdDefaultFilter,
        ...externalFilter,
      });
      setLoadHrCloudChartData(true);
      setHrCloudChartLoadState("loading");
      setLoadAuth(true);
    }
  }, [externalFilter]);

  // A custom hook to Load All HrCloudChart Details
  const hrCloudChartdDataResult = useFormatApiRequest(
    () => sampleApiCall(hrCloudChartdDefaultFilter),
    loadHrCloudChartData,
    () => {
      setLoadHrCloudChartData(false);
    },
    () => {
      processHrCloudChartResult();
    }
  );

  // Process The Current HrCloudChart Data Result
  const processHrCloudChartResult = async () => {
    if (hrCloudChartdDataResult.httpState === "SUCCESS") {
      // setTableData(hrCloudChartdDataResult.data?.data || [{}]);
      dispatch({
        type: "ADMIN_ADD_DASHBOARD_DATA",
        payload: hrCloudChartdDataResult.data?.data,
      });
      setHrCloudChartLoadState("completed");
    } else if (hrCloudChartdDataResult.httpState === "ERROR") {
      setHrCloudChartLoadState("error");
    } else if (hrCloudChartdDataResult.httpState === "LOADING") {
      setHrCloudChartLoadState("loading");
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}
      <div>
        {/* " Show Loading Indicator" */}
        {hrCloudChartdLoadState === "loading" && (
          <div
            className="w3-col w3-center w3-padding-bottom"
            style={{ paddingTop: "100px" }}
          >
            <Spin size="large" />
          </div>
        )}

        {/* " Show Loading Error" */}
        {hrCloudChartdLoadState === "error" && (
          <div className="w3-col w3-padding-bottom">
            <Result
              status="500"
              title={<span className="w3-text-white">Error</span>}
              subTitle={
                <span className="w3-text-white">
                  Sorry, something went wrong, it could be a network Related
                  error
                </span>
              }
              extra={
                <Button
                  onClick={() => setLoadHrCloudChartData(true)}
                  type="primary"
                >
                  Reload
                </Button>
              }
            />
          </div>
        )}

        {/* " Show No data" */}
        {hrCloudChartdLoadState === "noData" && (
          <div className="w3-margin-top">
            <Empty></Empty>
          </div>
        )}

        {/* " Show No data" */}
        {hrCloudChartdLoadState === "completed" && (
          <>
            <div className="w3-col w3-margin-top">
              <GeneralCharts></GeneralCharts>
            </div>
          </>
        )}
      </div>
    </>
  );
};
