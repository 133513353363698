import { useState } from "react";
import { IAuthType } from "../../../../apiservice/authService.type";
import { useAppSelector } from "../../../../Redux/reduxCustomHook";
import { RootState } from "../../../../Redux/store";
import { HrTimeSheetList } from "./HrTimeSheetList";
import "../HrTimeAndAttedance.css";
import HrTimeActivtySummary from "../HrTimeActivtySummary";

export const HrTimeSheetListWrapper = () => {
  const [payLoadFilter, setpayLoadFilter] = useState<any>({});
  const [externalFilter, setExternalFilter] = useState<any>({});

  const authData: IAuthType = useAppSelector(
    (state: RootState) => state?.AuthData
  );

  // Use to collect Change
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    // console.log(value)
    setpayLoadFilter((values) => ({ ...values, [name]: value }));
    if (name === "status") {
      setExternalFilter({ ...externalFilter, status: value });
    }
  };

  const handleButtonClick = (value) => {
    setpayLoadFilter((values) => ({ ...values }));
  };

  // Use to Update Filter
  const updateFilter = () => {
    setExternalFilter({ ...payLoadFilter });
  };

  return (
    <>
      <div className="w3-col">
        <div className="w3-col w3-margin-top">
          <HrTimeActivtySummary></HrTimeActivtySummary>
          <HrTimeSheetList></HrTimeSheetList>
        </div>
      </div>
    </>
  );
};

export default HrTimeSheetListWrapper;
