import "./HrEmployeeEngagement.css";
import HrEmployeeEngagementMenu from "./HrEmployeeEngagementMenu";
import HrSuggestionWrapper from "./HrEngagementLists/hrSuggestionListWrapper";

export const HrEmployeeEngagementSuggestionWrapper = () => {
  return (
    <>
      <div className="w3-col" style={{ minHeight: "400px" }}>
        <div className="w3-col" style={{ marginTop: "20px" }}>
          <div className="w3-container">
            <div className="w3-content">
              <div className="w3-col w3-margin-bottom">
                <h2 className="siteBorderPrimaryColor w3-xlarge">
                 Suggestions
                </h2>
                <br />
                <p>
                  Borem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                  vulputate libero et velit interdum, ac aliquet odio mattis.
                </p>
              </div>

              <HrEmployeeEngagementMenu></HrEmployeeEngagementMenu>

              <div className="w3-col w3-margin-bottom">
                <HrSuggestionWrapper></HrSuggestionWrapper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HrEmployeeEngagementSuggestionWrapper;
