import { Button, Result, Spin, Tabs, TabsProps } from "antd";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { sampleApiCall } from "../../../apiservice/authService";
import useFormatApiRequest from "../../../hooks/formatApiRequest";
import { ILoadState } from "../../../utils/loading.utils.";
import { HrEmployeeDetailCard } from "./hrEmployeeDetailCard";
import "./hrEmployeeManagementComp.css";

export const HrEmployeeDetails = () => {
  const [userLoadState, setUserLoadState] = useState<ILoadState>("loading");
  const [loadCurrentUser, setLoadCurrentUser] = useState(true);
  const [usersData, setUsersDataData] = useState<any | null>(null);
  const params = useParams();

  const onChange = (key: string) => {
    console.log(key);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Personal",
      children: <></>,
    },
    {
      key: "2",
      label: "Employment History",
      children: <></>,
    },
    {
      key: "3",
      label: "Performance Matrics",
      children: <></>,
    },
    {
      key: "4",
      label: "Documents",
      children: <></>,
    },
  ];

  // A custom hook to Load Current/Active User based on the Url
  const resultCurrentUser = useFormatApiRequest(
    () => sampleApiCall(Number(params?.userId)),
    loadCurrentUser,
    () => {
      setLoadCurrentUser(false);
    },
    () => {
      processCurrentPortalResult();
    }
  );

  // Process The Current User Data Result
  const processCurrentPortalResult = async () => {
    if (resultCurrentUser.httpState === "SUCCESS") {
      setUsersDataData(resultCurrentUser.data?.data);
      setUserLoadState("completed");
    } else if (resultCurrentUser.httpState === "ERROR") {
      setUserLoadState("error");
    }
  };

  return (
    <>
      {/* " Show Loading Indicator" */}
      {userLoadState === "loading" && (
        <div
          className="w3-col w3-center w3-padding-bottom"
          style={{ paddingTop: "100px" }}
        >
          <Spin size="large" />
        </div>
      )}

      {/* " Show Loading Error" */}
      {userLoadState === "error" && (
        <div className="w3-col w3-padding-bottom">
          <Result
            status="500"
            title="Error"
            subTitle="Sorry, something went wrong, it could be a network Related error"
            extra={
              <Button onClick={() => setLoadCurrentUser(true)} type="primary">
                Reload
              </Button>
            }
          />
        </div>
      )}

      {/* " Show No Data/Broken Link" */}
      {userLoadState === "noData" && (
        <div className="w3-col w3-padding-bottom">
          <Result
            status="warning"
            title="Seems this link has being deleted or it is broken create a new portal."
            extra={<Button type="primary">Go Back to User Management</Button>}
          />
        </div>
      )}

      {/* " Display form when User Load State is Not loading for new form entry & completed for editing old entry" */}
      {userLoadState === "completed" && (
        <>
          <div className="w3-content">
            <div className="w3-container">
              <div className="w3-col w3-padding-bottom">
                <HrEmployeeDetailCard></HrEmployeeDetailCard>
              </div>

              <div className="w3-col w3-padding-bottom">
                <Tabs
                  tabBarStyle={{
                    borderBottom: "1px solid #0D333E",
                    fontSize: "16px",
                  }}
                  style={{ color: "red" }}
                  defaultActiveKey="1"
                  items={items}
                  onChange={onChange}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default HrEmployeeDetails;
