import { Link } from "react-router-dom";
import "./HrCloudDashboard.css";

export const HRCloudDashboardMenu = () => {
  type IMenuType = {
    icon: string | any;
    url: string;
    title: string;
    onClick?: () => void;
  };

  const menu: IMenuType[] = [
    {
      icon: (
        <img
          style={{ width: "20px", maxWidth: "100%" }}
          alt="logo"
          src={process.env.PUBLIC_URL + "/images/hrcloud/iconemployeeDb.svg"}
        />
      ),
      url: "/hr/employee-management",
      title: "Employee Database",
    },
    {
      icon: (
        <img
          style={{ width: "20px", maxWidth: "100%" }}
          alt="logo"
          src={
            process.env.PUBLIC_URL + "/images/hrcloud/iconleavemanagement.svg"
          }
        />
      ),
      url: "/hr/leave-management",
      title: "Leave Management",
    },
    {
      icon: (
        <img
          style={{ width: "20px", maxWidth: "100%" }}
          alt="logo"
          src={
            process.env.PUBLIC_URL + "/images/hrcloud/icontimeAttenedance.svg"
          }
        />
      ),
      url: "/hr/time-attendance",
      title: "Time & Attandance",
    },
    {
      icon: (
        <img
          style={{ width: "20px", maxWidth: "100%" }}
          alt="logo"
          src={process.env.PUBLIC_URL + "/images/hrcloud/icontraining.svg"}
        />
      ),
      url: "/hr/training",
      title: "Training & Development",
    },
    {
      icon: (
        <img
          style={{ width: "20px", maxWidth: "100%" }}
          alt="logo"
          src={process.env.PUBLIC_URL + "/images/hrcloud/iconHrAnalytics.svg"}
        />
      ),
      url: "/hr/analytics",
      title: "HR analytics & reporting",
    },
    {
      icon: (
        <img
          style={{ width: "20px", maxWidth: "100%" }}
          alt="logo"
          src={process.env.PUBLIC_URL + "/images/hrcloud/iconemployeeDb.svg"}
        />
      ),
      url: "/hr/recruitment",
      title: "Recruitment & onboard",
    },
    {
      icon: (
        <img
          style={{ width: "20px", maxWidth: "100%" }}
          alt="logo"
          src={process.env.PUBLIC_URL + "/images/hrcloud/iconperformance.svg"}
        />
      ),
      url: "/hr/performance",
      title: "Performance MGT",
    },
    {
      icon: (
        <img
          style={{ width: "20px", maxWidth: "100%" }}
          alt="logo"
          src={process.env.PUBLIC_URL + "/images/hrcloud/iconpayroll.svg"}
        />
      ),
      url: "/hr/payroll",
      title: "Payroll",
    },
    {
      icon: (
        <img
          style={{ width: "20px", maxWidth: "100%" }}
          alt="logo"
          src={
            process.env.PUBLIC_URL +
            "/images/hrcloud/iconemeployeeEngagement.svg"
          }
        />
      ),
      url: "/hr/employee-engagement",
      title: "Employee Engagement",
    },
  ];
  return (
    <>
      <div className="w3-col" style={{ minHeight: "200px" }}>
        {menu.map((item: IMenuType, index: number) => (
          <div className="w3-col l4 s12 m6" style={{ padding: "5px" }}>
            <div className="w3-col sitePrimaryBorderButton w3-border w3-round-xlarge w3-padding">
              <Link to={item.url}>
                <span> {item.icon}</span> <span> {item.title}</span>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default HRCloudDashboardMenu;
