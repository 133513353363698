import { PlusCircleOutlined } from "@ant-design/icons";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import HrCloudLeaveChartWrapper from "./HrCloudLeaveChartWrapper";
import HRCloudLeaveManagementCalenderComp from "./HrCloudLeaveManagement-Calender";
import "./HrCloudLeaveManagement.css";
import HrLeaveRequestListWrapper from "./hrLeaveList/HrCloudLeaveEmployeeRequestListWrapper";

export const HRCloudLeaveManagementComp = () => {
  return (
    <>
      <div className="w3-col" style={{ minHeight: "400px" }}>
        <div className="w3-col" style={{ marginTop: "00px" }}>
          <div className="w3-container">
            <div className="w3-content">
              <div className="w3-col w3-margin-bottom">
                <div className="w3-col  w3-margin-bottom">
                  <Breadcrumb
                    items={[
                      {
                        title: (
                          <span className="siteBorderPrimaryColor w3-large">
                            <Link to={"/hr"}>HRCLOUD</Link>
                          </span>
                        ),
                      },
                      {
                        title: (
                          <span className="siteBorderPrimaryColor w3-large">
                            Leave Management
                          </span>
                        ),
                      },
                    ]}
                  />
                </div>

                <div className="w3-col w3-right-align">
                  <Link
                    to={"/hr/leave-management/leaveRequestForm"}
                    className="sitePrimaryButton w3-btn w3-round-large"
                  >
                    Apply Form <PlusCircleOutlined />
                  </Link>
                  &nbsp;
                  <Link
                    to={"/hr/leave-management/leavePolicyForm"}
                    className="siteButtonNoColor w3-green w3-btn w3-round-large"
                  >
                    Create Leave Policy <PlusCircleOutlined />
                  </Link>
                </div>
              </div>
              <HRCloudLeaveManagementCalenderComp></HRCloudLeaveManagementCalenderComp>
              <HrCloudLeaveChartWrapper></HrCloudLeaveChartWrapper>
              <HrLeaveRequestListWrapper></HrLeaveRequestListWrapper>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HRCloudLeaveManagementComp;
