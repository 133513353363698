import "./hrEmployeeRecruitment.css";
import HrEmployeeRecruitmentListWrapper from "./HrRecruitmentLists/hrEmployeeRecruitmentListWrapper";

export const HRRecentRecruitmentWrapper = () => {
  return (
    <>
      <div className="w3-col" style={{ minHeight: "400px" }}>
        <div className="w3-col" style={{ marginTop: "20px" }}>
          <div className="w3-container">
            <div className="w3-content">
              <div className="w3-col w3-margin-bottom">
                <h2 className="siteBorderPrimaryColor w3-xlarge">
                  Recruitment And Onboarding
                </h2>
                <br />
                <p>
                  Borem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                  vulputate libero et velit interdum, ac aliquet odio mattis.
                </p>
              </div>

              <HrEmployeeRecruitmentListWrapper></HrEmployeeRecruitmentListWrapper>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HRRecentRecruitmentWrapper;
