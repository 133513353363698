import { Link } from "react-router-dom";
import "../userPage.css";

export const MoreServicesPage = () => {
  type IMenuType = {
    icon: string | any;
    url: string;
    title: string;
    onClick?: () => void;
  };

  const menu: IMenuType[] = [
    {
      icon: process.env.PUBLIC_URL + "/images/hrcloud/iconemployeeDb.svg",
      url: "/hr/",
      title: "Wokrr HRM",
    },
    {
      icon: process.env.PUBLIC_URL + "/images/hrcloud/iconemployeeDb.svg",
      url: "/hr/",
      title: "Wokrr Stock Management",
    },
    {
      icon: process.env.PUBLIC_URL + "/images/hrcloud/iconemployeeDb.svg",
      url: "/hr/",
      title: "Wokrr POS",
    },
    {
      icon: process.env.PUBLIC_URL + "/images/hrcloud/iconemployeeDb.svg",
      url: "/hr/",
      title: "Wokrr Project Management",
    },
  ];
  return (
    <>
      {" "}
      <div className="w3-col" style={{ minHeight: "300px" }}>
        <div className="w3-col" style={{ marginTop: "20px" }}>
          <div className="w3-container">
            <div className="w3-content">
              <div className="w3-col w3-margin-bottom">
                <h2 className="siteBorderPrimaryColor w3-xlarge">
                  More Service
                </h2>
                <p>
                  Borem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                  vulputate libero et velit interdum, ac aliquet odio mattis.
                  <br />
                  Class aptent taciti sociosqu ad litora torquent per conubia
                  nostra, per inceptos himenaeos.
                </p>
              </div>

              <div className="w3-col" style={{ minHeight: "200px" }}>
                {menu.map((item: IMenuType, index: number) => (
                  <div className="w3-col l4 s12 m6" style={{ padding: "5px" }}>
                    <div className="w3-col w3-border w3-center w3-round-xlarge w3-padding">
                      <Link to={item.url}>
                        <br /> <br />
                        <img
                          style={{ height: "64px", maxWidth: "100%" }}
                          alt="logo"
                          src={item.icon}
                        />
                        <br /> <br />
                        <span className="w3-large"> {item.title}</span>
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MoreServicesPage;
