import { Calendar } from "antd";
import type { CalendarProps } from "antd";
import type { Dayjs } from "dayjs";
import "./HrEmployeePortal.css";

export const HREmployeeCalendar = () => {
  const onPanelChange = (value: Dayjs, mode: CalendarProps<Dayjs>["mode"]) => {
    console.log(value.format("YYYY-MM-DD"), mode);
  };

  return (
    <>
      <div className="w3-col" style={{ minHeight: "400px" }}>
        <div className="w3-col" style={{ padding: "5px" }}>
          <div className="w3-border w3-round-xlarge w3-padding">
            <p className="sitePrimaryColor w3-large">
              {" "}
              <b> My Calender</b>{" "}
            </p>
            <Calendar onPanelChange={onPanelChange} fullscreen={false} />
          </div>
        </div>
      </div>
    </>
  );
};

export default HREmployeeCalendar;
