import HrEmployeeEngagementWrapper from "../../../components/HRUsersComp/hrEmployeeEngagement/HrEmployeeEngagementWrapper";
import "../userPage.css";

export const HREmployeeEngagementPage = () => {
  return (
    <>
      <HrEmployeeEngagementWrapper></HrEmployeeEngagementWrapper>
    </>
  );
};

export default HREmployeeEngagementPage;
