import HrEmployeeDatabaseListWrapper from "../../../components/HRUsersComp/hrEmployeeManagementComp/hrEmployeeList/hrEmployeeDatabaseListWrapper";
import "../userPage.css";

export const HREmployeeManagmentPage = () => {
  return (
    <>
      <HrEmployeeDatabaseListWrapper></HrEmployeeDatabaseListWrapper>
    </>
  );
};

export default HREmployeeManagmentPage;
