import HRRecentRecruitmentWrapper from "../../../components/HRUsersComp/hrEmployeeRecruitment/hrEmployeeRecruitmentWrapper";
import "../userPage.css";

export const HRRecruitmentPage = () => {
  return (
    <>
      <HRRecentRecruitmentWrapper></HRRecentRecruitmentWrapper>
    </>
  );
};

export default HRRecruitmentPage;
