import "./HrCloudLeaveManagement.css";

export const HrLeaveRequestSummary: React.FC<{}> = () => {
  return (
    <>
      <div className="w3-col w3-margin-bottom">
        {/* Total Request */}
        <div className="w3-col l3 s6 m3 w3-padding w3-leftbar w3-margin-bottom totalRequestBorder">
          <span className="requestSummaryTitle"> Total Request </span>
          <br />
          <span className="w3-xxlarge totalRequestNumberText">62 </span>
        </div>

        {/* New Request */}
        <div className="w3-col l3 s6 m3 w3-padding w3-leftbar w3-margin-bottom newRequestBorder">
          <span className="requestSummaryTitle"> New Request </span>
          <br />
          <span className="sitePrimaryColor w3-xxlarge newRequestNumberText">
            42
          </span>
        </div>

        {/* Rejected Request */}
        <div className="w3-col l3 s6 m3 w3-padding w3-leftbar w3-margin-bottom rejectedRequestBorder">
          <span className="requestSummaryTitle"> Rejected Request </span>
          <br />
          <span className="sitePrimaryColor w3-xxlarge rejectedRequestNumberText">
            10
          </span>
        </div>

        {/* Pending Request */}
        <div className="w3-col l3 s6 m3 w3-padding w3-leftbar w3-margin-bottom pendingRequestBorder">
          <span className="requestSummaryTitle"> Pending Request </span>
          <br />
          <span className="sitePrimaryColor w3-xxlarge pendingRequestNumberText">
            10
          </span>
        </div>
      </div>
    </>
  );
};

export default HrLeaveRequestSummary;
