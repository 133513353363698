import HrTrainingWrapper from "../../../components/HRUsersComp/hrTraining/HrTrainingWrapper";
import "../userPage.css";

export const HRTrainingPage = () => {
  return (
    <>
      <HrTrainingWrapper></HrTrainingWrapper>
    </>
  );
};

export default HRTrainingPage;
