import HREmployeeCalendar from "./HrEmployee-Calender";
import HRRecentTraining from "./HrEmployeePortal-RecentTraining";
import "./HrEmployeePortal.css";
import HREmployeePortalCard from "./HrEmployeePortalProfileCard";

export const HREmployeePortalWrapper = () => {
  return (
    <>
      <div className="w3-col" style={{ minHeight: "400px" }}>
        <div className="w3-col" style={{ marginTop: "20px" }}>
          <div className="w3-container">
            <div className="w3-content">
              <HREmployeePortalCard></HREmployeePortalCard>

              <div className="w3-col" style={{marginTop:"20px"}}>
                <div className="w3-col l6 s12 m6" style={{ padding: "5px" }}>
                  <HREmployeeCalendar></HREmployeeCalendar>
                </div>

                <div className="w3-col l6 s12 m6" style={{ padding: "5px" }}>
                  <HRRecentTraining></HRRecentTraining>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HREmployeePortalWrapper;
