import { Button, notification, Pagination, Result, Tag } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { sampleApiCall } from "../../../../apiservice/authService";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import { convertToShortDate } from "../../../../utils/date.utils";
import { ILoadState } from "../../../../utils/loading.utils.";
import HrLeaveRequestSummary from "../HrCloudLeaveEmployeeRequestSummary";
import "../HrCloudLeaveManagement.css";
type NotificationType = "success" | "info" | "warning" | "error";

type IHrLeaveRequestList = {
  externalFilter?: any;
  initialDefaultFilter?: any;
  hidePagination?: boolean;
};

export const HrLeaveRequestList: React.FC<IHrLeaveRequestList> = ({
  externalFilter,
  initialDefaultFilter,
  hidePagination = true,
}) => {
  const [employeeLeaveListLoadState, setEmployeeLeaveListLoadState] =
    useState<ILoadState>("loading");
  const [loadEmployeeLeaveListsData, setLoadEmployeeLeaveListsData] =
    useState(true);
  const [employeeLeaveListDefaultFilter, setEmployeeLeaveListDefaultFilter] =
    useState(initialDefaultFilter || { page: 1, perPage: 10 });
  const [tableData, setTableData] = useState<any[]>([]);
  const [api, contextHolder] = notification.useNotification();

  // Pagination Constant/Variables
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const perPage = 10;

  // Use Effect to reload API when External Filter has changed
  useEffect(() => {
    if (externalFilter) {
      setCurrentPage(1);
      setEmployeeLeaveListDefaultFilter({
        ...employeeLeaveListDefaultFilter,
        ...externalFilter,
        page: 1,
      });
      setLoadEmployeeLeaveListsData(true);
      setEmployeeLeaveListLoadState("loading");
    }
  }, [externalFilter]);

  // A custom hook to Load All EmployeeLeaveLists
  const EmployeeLeaveListDataResult = useFormatApiRequest(
    () => sampleApiCall(employeeLeaveListDefaultFilter),
    loadEmployeeLeaveListsData,
    () => {
      setLoadEmployeeLeaveListsData(false);
    },
    () => {
      processEmployeeLeaveListsResult();
    }
  );

  // Process The Current EmployeeLeaveList Data Result
  const processEmployeeLeaveListsResult = async () => {
    if (EmployeeLeaveListDataResult.httpState === "SUCCESS") {
      setTableData(EmployeeLeaveListDataResult.data?.data || [{}, {}, {}, {}]);
      setTotalItems(EmployeeLeaveListDataResult.data?.meta?.total || 1);
      setEmployeeLeaveListLoadState("completed");
    } else if (EmployeeLeaveListDataResult.httpState === "ERROR") {
      setEmployeeLeaveListLoadState("error");
    } else if (EmployeeLeaveListDataResult.httpState === "LOADING") {
      setEmployeeLeaveListLoadState("loading");
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  // Table Configuration
  const columns: ColumnsType<any> = [
    {
      title: "Employee Name",
      dataIndex: "employeeName",
      key: "employeeName",
      render: (text, records, index) => <span>{"James Manager"}</span>,
    },
    {
      title: "Leave Type",
      dataIndex: "leaveType",
      key: "leaveType",
      render: (text, records, index) => (
        <Tag className="tagText" color={"green"}>
          {"Annual Leave"}
        </Tag>
      ),
    },
    {
      title: "Decription",
      dataIndex: "description",
      key: "description",
      render: (text, records, index) => <span>{"Annual Holiday Leave"}</span>,
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, records, index) => (
        <Tag className="tagText" color={"green"}>
          {"Approved"}
        </Tag>
      ),
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      render: (text, records, index) => <span>{"2 Days"}</span>,
    },
    {
      title: "Date",
      dataIndex: "dateModified",
      key: "dateModified",
      render: (text) => <span>{convertToShortDate(text)}</span>,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 170,
      fixed: "right",
      render: (text, records, index) => (
        <>
          <div className="w3-center">
            <span className="siteTableBtn w3-tiny w3-green w3-round">
              Approve
            </span>
            &nbsp;
            <span className="siteTableBtn w3-tiny w3-red w3-round">Reject</span>
          </div>
        </>
      ),
    },
  ];

  // Use to Control Pagination On Change Event
  const onPageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setEmployeeLeaveListDefaultFilter({
      ...employeeLeaveListDefaultFilter,
      page: page,
      perPage: pageSize,
    });
    setLoadEmployeeLeaveListsData(true);
  };

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}

      {/* " Show Loading Error" */}
      {employeeLeaveListLoadState === "error" && (
        <div className="w3-col w3-padding-bottom">
          <Result
            status="500"
            title="Error"
            subTitle="Sorry, something went wrong, it could be a network Related error"
            extra={
              <Button
                onClick={() => setLoadEmployeeLeaveListsData(true)}
                type="primary"
              >
                Reload
              </Button>
            }
          />
        </div>
      )}

      {/* " Show Loading Table" */}
      {employeeLeaveListLoadState !== "error" && (
        <>
          <HrLeaveRequestSummary></HrLeaveRequestSummary>
          <div className="w3-col">
            <div className="w3-border w3-padding w3-round-xlarge">
              <p className="sitePrimaryColor w3-large">
                <b> Leave Approval </b>
              </p>
              <Table
                className="w3-round-large"
                loading={employeeLeaveListLoadState === "loading"}
                rowKey="id"
                size="small"
                columns={columns}
                dataSource={tableData}
                scroll={{ x: 950 }}
                pagination={{ hideOnSinglePage: true }}
              />
            </div>
          </div>

          {!hidePagination && (
            <div className="w3-col w3-margin-top">
              <Pagination
                current={currentPage || 1}
                onChange={onPageChange}
                pageSize={perPage}
                total={totalItems}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default HrLeaveRequestList;
