import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./HrEmployeeEngagement.css";

export const HrEmployeeEngagementMenu = () => {
  type IMenuType = {
    icon: string | any;
    url: string;
    title: string;
    onClick?: () => void;
  };

  const location = useLocation();
  const [currentUrlPath, setCurrentUrlPath] = useState("");

  const menu: IMenuType[] = [
    {
      icon: (
        <img
          style={{ width: "20px", maxWidth: "100%" }}
          alt="logo"
          src={process.env.PUBLIC_URL + "/images/hrcloud/iconpayroll.svg"}
        />
      ),
      url: "/hr/employee-engagement",
      title: "FeedBack Form",
    },
    {
      icon: (
        <img
          style={{ width: "20px", maxWidth: "100%" }}
          alt="logo"
          src={
            process.env.PUBLIC_URL + "/images/hrcloud/iconleavemanagement.svg"
          }
        />
      ),
      url: "/hr/employee-engagement-suggestion",
      title: "Suggestion Box",
    },
    {
      icon: (
        <img
          style={{ width: "20px", maxWidth: "100%" }}
          alt="logo"
          src={process.env.PUBLIC_URL + "/images/hrcloud/icontraining.svg"}
        />
      ),
      url: "/hr/employee-engagement-survey",
      title: "Survey",
    },
  ];

  useEffect(() => {
    // execute on location change
    setCurrentUrlPath(location.pathname);
  }, [location]);
  return (
    <>
      <div className="w3-col" style={{ minHeight: "60px" }}>
        {menu.map((item: IMenuType, index: number) => (
          <div className="w3-col l4 s12 m6" style={{ padding: "5px" }}>
            <div
              className={
                "w3-col sitePrimaryBorderButton w3-border w3-round-xlarge w3-padding " +
                (currentUrlPath === item.url ? " sitePrimaryButton" : "")
              }
            >
              <Link to={item.url}>
                {item.icon} {item.title}
              </Link>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default HrEmployeeEngagementMenu;
