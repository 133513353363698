import { useState } from "react";
import { IAuthType } from "../../../../apiservice/authService.type";
import { useAppSelector } from "../../../../Redux/reduxCustomHook";
import { RootState } from "../../../../Redux/store";
import "../HrTimeAndAttedance.css";
import { Input, Modal } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { appZIndex } from "../../../../utils/appconst";
import HrAttendanceEmployeeList from "./HrAttendanceEmployeeList";

export const HrAttendanceEmployeeListWrapper = () => {
  const [payLoadFilter, setpayLoadFilter] = useState<any>({});
  const [externalFilter, setExternalFilter] = useState<any>({});
  const authData: IAuthType = useAppSelector(
    (state: RootState) => state?.AuthData
  );

  // Use to collect Change
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    // console.log(value)
    setpayLoadFilter((values) => ({ ...values, [name]: value }));
    if (name === "status") {
      setExternalFilter({ ...externalFilter, status: value });
    }
  };

  const handleButtonClick = (value) => {
    setpayLoadFilter((values) => ({ ...values }));
  };

  // Use to Update Filter
  const updateFilter = () => {
    setExternalFilter({ ...payLoadFilter });
  };

  return (
    <>
      <div className="w3-col">
        {/* Filters */}
        <div className="w3-col w3-margin-top">
          <div className="w3-col l7 s12 m12">
            <div className="w3-col" style={{ padding: "5px" }}>
              <Input
                name="searchstring"
                value={payLoadFilter?.searchstring || ""}
                onChange={handleInputChange}
                onBlur={updateFilter}
                className="w3-col w3-round-large inputSelect"
                style={{ height: "50px" }}
                placeholder="Search"
              />
            </div>
          </div>

          <div className="w3-col l5 s12 m12">
            <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
              <select
                name="status"
                value={payLoadFilter?.status || "-1"}
                onChange={handleInputChange}
                onBlur={updateFilter}
                className="w3-col w3-round-large w3-border w3-text-black"
                style={{ height: "50px" }}
              >
                <option value="">Filter</option>
                <option value="-1"> No Filter</option>
              </select>
            </div>
            <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
              <button className="w3-btn w3-col w3-round-large sitePrimaryBorderButton">
                Search
              </button>
            </div>
          </div>
        </div>

        <div className="w3-col w3-margin-top">
          <HrAttendanceEmployeeList></HrAttendanceEmployeeList>
        </div>
      </div>
    </>
  );
};

export default HrAttendanceEmployeeListWrapper;
