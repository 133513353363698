import { Button, notification, Pagination, Result, Tag } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { sampleApiCall } from "../../../../apiservice/authService";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import { convertToShortDate } from "../../../../utils/date.utils";
import { ILoadState } from "../../../../utils/loading.utils.";
import "../HrTraining.css";
type NotificationType = "success" | "info" | "warning" | "error";

type IHrTrainingtList = {
  externalFilter?: any;
  initialDefaultFilter?: any;
  hidePagination?: boolean;
};

export const HrTrainingtList: React.FC<IHrTrainingtList> = ({
  externalFilter,
  initialDefaultFilter,
  hidePagination = true,
}) => {
  const [trainingListLoadState, setTrainingListLoadState] =
    useState<ILoadState>("loading");
  const [loadtrainingListsData, setLoadTrainingListsData] = useState(true);
  const [trainingListDefaultFilter, setTrainingListDefaultFilter] = useState(
    initialDefaultFilter || { page: 1, perPage: 10 }
  );
  const [tableData, setTableData] = useState<any[]>([]);
  const [api, contextHolder] = notification.useNotification();

  // Pagination Constant/Variables
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const perPage = 10;

  // Use Effect to reload API when External Filter has changed
  useEffect(() => {
    if (externalFilter) {
      setCurrentPage(1);
      setTrainingListDefaultFilter({
        ...trainingListDefaultFilter,
        ...externalFilter,
        page: 1,
      });
      setLoadTrainingListsData(true);
      setTrainingListLoadState("loading");
    }
  }, [externalFilter]);

  // A custom hook to Load All trainingLists
  const trainingListDataResult = useFormatApiRequest(
    () => sampleApiCall(trainingListDefaultFilter),
    loadtrainingListsData,
    () => {
      setLoadTrainingListsData(false);
    },
    () => {
      processtrainingListsResult();
    }
  );

  // Process The Current trainingList Data Result
  const processtrainingListsResult = async () => {
    if (trainingListDataResult.httpState === "SUCCESS") {
      setTableData(trainingListDataResult.data?.data || [{}, {}, {}, {}]);
      setTotalItems(trainingListDataResult.data?.meta?.total || 1);
      setTrainingListLoadState("completed");
    } else if (trainingListDataResult.httpState === "ERROR") {
      setTrainingListLoadState("error");
    } else if (trainingListDataResult.httpState === "LOADING") {
      setTrainingListLoadState("loading");
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  // Table Configuration
  const columns: ColumnsType<any> = [
    {
      title: "Course Name",
      dataIndex: "courseName",
      key: "courseName",
      render: (text, records, index) => <span>{"Design System"}</span>,
    },
    {
      title: "Assigned To",
      dataIndex: "assignedTo",
      key: "assignedTo",
      render: (text, records, index) => <span>{"All Employee"}</span>,
    },
    {
      title: "Dead Line",
      dataIndex: "deadline",
      key: "deadline",
      render: (text) => <span>{convertToShortDate("12-02-2025")}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, records, index) => (
        <Tag className="tagText" color={"green"}>
          {"Approved"}
        </Tag>
      ),
    },
    {
      title: "Date",
      dataIndex: "dateModified",
      key: "dateModified",
      render: (text) => <span>{convertToShortDate(text)}</span>,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 170,
      fixed: "right",
      render: (text, records, index) => (
        <>
          <div className="w3-center">
            <span className="siteTableBtn w3-tiny w3-green w3-round">
              Track
            </span>
            &nbsp;
            <span className="siteTableBtn w3-tiny w3-blue w3-round">
              Details
            </span>
          </div>
        </>
      ),
    },
  ];

  // Use to Control Pagination On Change Event
  const onPageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setTrainingListDefaultFilter({
      ...trainingListDefaultFilter,
      page: page,
      perPage: pageSize,
    });
    setLoadTrainingListsData(true);
  };

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}

      {/* " Show Loading Error" */}
      {trainingListLoadState === "error" && (
        <div className="w3-col w3-padding-bottom">
          <Result
            status="500"
            title="Error"
            subTitle="Sorry, something went wrong, it could be a network Related error"
            extra={
              <Button
                onClick={() => setLoadTrainingListsData(true)}
                type="primary"
              >
                Reload
              </Button>
            }
          />
        </div>
      )}

      {/* " Show Loading Table" */}
      {trainingListLoadState !== "error" && (
        <>
          <div className="w3-col">
            <div className="w3-border w3-padding w3-round-xlarge">
              <Table
                className="w3-round-large"
                loading={trainingListLoadState === "loading"}
                rowKey="id"
                size="small"
                columns={columns}
                dataSource={tableData}
                scroll={{ x: 950 }}
                pagination={{ hideOnSinglePage: true }}
              />
            </div>
          </div>

          {!hidePagination && (
            <div className="w3-col w3-margin-top">
              <Pagination
                current={currentPage || 1}
                onChange={onPageChange}
                pageSize={perPage}
                total={totalItems}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default HrTrainingtList;
